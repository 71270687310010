import request from '@/utils/axios'

/** 获取banner图列表 */
export function getList(params) {
  return request({
    url: '/banner',
    method: 'get',
    params
  })
}

/** 获取banner图详情 */
export function getDetail(id) {
  return request({
    url: `/banner/detail?id=${id}`,
    method: 'get'
  })
}

/** 添加banner图 */
export function add(data) {
  return request({
    url: '/banner',
    method: 'post',
    data
  })
}

/** 编辑banner图 */
export function edit(data) {
  return request({
    url: '/banner',
    method: 'put',
    data
  })
}

/** 删除banner图 */
export function del(id) {
  return request({
    url: `/banner?id=${id}`,
    method: 'delete'
  })
}

// 启用轮播图
export function enable(params) {
  return request({
    url: '/banner/enable',
    method: 'get',
    params
  })
}

// 禁用轮播图
export function disable(params) {
  return request({
    url: '/banner/disable',
    method: 'get',
    params
  })
}

